import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { InteractiveDemoForm } from "../components/forms";
import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahLogo from "../images/logos/Droppah/Droppah_Full_White.svg";
import BlurredBR from "../images/graphics/DroppahBlurredBR.png";

const DemoContainer = styled(Container)`
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-height: 1000px;
  background-image: url(${BlurredBR});
  background-size: cover;
  background-repeat: no-repeat;
`;

const DemoForm = styled(Box)`
  position: relative;
  width: 95%;
  max-width: 500px;
  background: ${(props) => props.theme.colours.black};
  padding: 30px 40px;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;
  }
`;

const InteractiveDemo = (props) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.classList.remove("-stopScrollMobile");
    }
  }, []);

  return (
    <Layout fullPage>
      <Seo
        title="Try a Demo | Free Interactive Online Demo | Droppah"
        description="Get a feel for what Droppah has to offer your business right now with our free online interactive demo. Create a free account today."
        pathname={props.location.pathname}
      />
      <DemoContainer>
        <Wrapper stackGap={80} className="-textCenter">
          <Box stackGap={50}>
            <Link to="/">
              <img
                className="-center"
                src={DroppahLogo}
                alt="Try a Demo | Droppah | Rosters, Timesheets & Attendance Software"
                width={250}
              />
            </Link>
            <DemoForm className="-center" stackGap={30}>
              <div>
                <h2>Try a Demo</h2>
                <p>Explore Droppah with a fully interactive demo company</p>
              </div>
              <Box>
                <InteractiveDemoForm />
              </Box>
              <Box stackGap={10}>
                <p>
                  Want to create your own account?{" "}
                  <br className="hideOnMobile" />
                  <Link to="/signup">Get started for free</Link>.
                </p>
              </Box>
            </DemoForm>
          </Box>
        </Wrapper>
      </DemoContainer>
    </Layout>
  );
};

export default InteractiveDemo;
